import React from "react"
import ReactGA from "react-ga"
import Layout from "../components/common/Layout"
import { getBackLink, getHeaderTitles } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { graphql, useStaticQuery } from "gatsby"
import HeroVideo from "~/components/page/HeroVideo"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { Heading, Text } from "~/components/common/Typography"
import { Box } from "reflexbox"
import Button from "~/components/common/Button"
import Copyright from "~/components/common/Copyright"

import certificate from "~/images/ADIDAS_CERTIFICATE.png"
import cheatSheet from "~/images/ADIDAS_CHEATSHEET.pdf"
import { css } from "@emotion/core"

const Page = ({ myPage = 6, mySubpage }) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(name: { eq: "7.1HERO" }) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 500, cropFocus: NORTH, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const { hero } = data

  const logDownload = type => {
    ReactGA.event({
      category: "file",
      action: "download",
      label: type,
    })
  }

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextLink: `/resources/`,
        nextText: `NEXT: RESOURCES`,
      }}
    >
      <HeroVideo
        headingLarge="As a coach, you have the responsibility to give girls the opportunity not only to participate, but to thrive in sports."
        image={hero}
        bg={"white"}
        text={
          "This means gaining the skills and confidence to break barriers, live healthily, and achieve success throughout their lives. This work is not just for girls; any benefit to them will better society as a whole."
        }
      />

      <Infobox uppercase>
        When it comes to facing common challenges, be they emotional, physical,
        or psychological, girls who play sports tend to fare better than those
        who do not. But despite sports’ proven benefits, girls’ participation is
        not a given. Countless obstacles, barriers, biases, and stereotypes work
        against it. So what does this mean for you as a coach? Girls need your
        encouragement, your expertise, your attention, and your care! Because if
        they quit, they may not play again.
        <br />
        <br />
        You have learned and practiced the tools to help you provide the best
        coaching. And you have the passion and enthusiasm to bring it all home.
        <br />
        <br />
        Regardless of how long you have been coaching, we want to thank you for
        offering your time and energy to change the lives of young people. We
        want to applaud your effort and your commitment. Few people have the
        inclination to coach, and even fewer follow that inclination and
        actually become coaches.
        <br />
        <Heading
          mt={6}
          color={"black"}
          fontFamily={"AdineuePROBlackWeb"}
          fontSize={["28px", "48px"]}
          lineHeight={["24px", "36px"]}
        >
          Now it’s time to go play.
        </Heading>
      </Infobox>
      <InfoboxDetailed
        headerText={
          "Screenshot and share your certificate of completion to get more coaches to Keep Girls in Sport"
        }
        contentMaxWidth={"tablet"}
        headerMaxWidth={"desktop"}
        mx={"auto"}
      >
        <Box width={["100%"]} mx={"auto"} mt={6}>
          <a
            href={certificate}
            onClick={() => logDownload("certificate")}
            target={"_blank"}
            css={css`
              text-decoration: none;

              &:hover {
                text-decoration: none;
              }
            `}
          >
            <Button
              height={["50px", "58px"]}
              minHeight={["50px", "58px"]}
              px={6}
            >
              VIEW CERTIFICATE
            </Button>
          </a>
          <Box mt={6} />
          <a
            href={cheatSheet}
            onClick={() => logDownload("cheat-sheet")}
            target={"_blank"}
            css={css`
              text-decoration: none;

              &:hover {
                text-decoration: none;
              }
            `}
          >
            <Button
              height={["50px", "58px"]}
              minHeight={["50px", "58px"]}
              px={6}
            >
              VIEW CHEAT SHEET
            </Button>
          </a>
        </Box>
      </InfoboxDetailed>

      <Copyright />
    </Layout>
  )
}

export default withProgress(Page, 6, 0)
